// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require('bootstrap');

import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
ActiveStorage.start()

require("@fortawesome/fontawesome-pro");

window.jQuery = $;
window.$ = $;
require("jquery-ui");

import Cookies from 'js-cookie'
window.Cookies = Cookies;

import '../stylesheets/bootstrap';
import '../stylesheets/sb-admin-2';
import '../stylesheets/fontawesome';
import '../stylesheets/devise';

require("./sb-admin-2.custom");
